import React, { useContext } from 'react'
import { Route, Routes, Redirect, Navigate } from 'react-router-dom'
import Home from './components/pages/home/Home'
// import CreateUser from './components/pages/CreateUser'
import EditProfile from './components/pages/edit-profile/EditProfile'
import { HomeContext, HomeProvider } from './context/HomeContext'
import Login from './components/pages/login/Login'
import MainDrawer from './components/pages/Nav/MainDrawer'
import PermanentDrawerRight from './components/pages/Nav/PermanentDrawer'
import { useScreenDimensions } from './utils/useScreenDimensions'
import Dashboard from './components/pages/dashboard/Dashboard'
import Clients from './components/pages/client/Clients'
import CreateClient from './components/pages/client/CreateClient'
import ClientsHistory from './components/pages/client-history/ClientsHistory'
import Second from './components/pages/events/Second'
import Event from './components/pages/events/Event'
import DeleteClient from './components/pages/delete/DeleteClient'
import ProjectsPage from './components/pages/project/ProjectPage'
import NotFound from './components/pages/not-found/NotFound'
import CreateNewProject from './components/pages/project/CreateNewProject'
const AppRouting = () => {
  const [width] = useScreenDimensions()

  const [auth, setAuth] = useContext(HomeContext)

  const Drawer = width < 780 ? MainDrawer : PermanentDrawerRight

  return (
    <>
      <HomeProvider value={[auth, setAuth]}>
        <Routes>
          <Route path="/iniciar-sesion" element={<Login />} />
          <Route path="*" element={<Navigate to="/proyectos" replace />} />{' '}
          {/* <Route path="*" element={<NotFound />} /> */}
          <Route element={<Drawer />}>
            {/* <Route exact path="/clientes/:slug/:id" element={<Second />} /> */}

            {/* <Route exact path="/" element={<Home />} /> */}
            <Route path="/reporte" element={<Dashboard />} />
            <Route path="/ingresar-evento" element={<Event />} />
            <Route path="/proyectos" element={<ProjectsPage />} />
            <Route exact path="/proyectos/:slug/:id" element={<Second />} />
            <Route
              exact
              path="/proyectos/crear"
              element={<CreateNewProject />}
            />
            <Route path="/clientes-historicos" element={<ClientsHistory />} />
            <Route path="/crear-cliente" element={<CreateClient />} />
            <Route path="/dar-de-baja" element={<DeleteClient />} />
            <Route path="/editar-perfil" element={<EditProfile />} />
            <Route path="/clientes" element={<Clients />} />
          </Route>
        </Routes>
      </HomeProvider>
    </>
  )
}

export default AppRouting
