import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { Fragment, useState } from 'react'
import { convertDate } from '../../../utils/formatedDate'

function Row({ project }) {
  const {
    name,
    type,
    slug,
    price,
    time,
    place,
    updatedAt,
    id,
    event_count,
    project_id,
  } = project
  const [open, setOpen] = useState(false)
  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{name}</TableCell>
        <TableCell align="center">{convertDate(updatedAt)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Detalles del Proyecto
              </Typography>
              <Table size="small" aria-label="project-details">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Tipo de Proyecto</TableCell>
                    <TableCell align="center">Monto</TableCell>
                    <TableCell align="center">Plazo</TableCell>
                    <TableCell align="center">Ubicación</TableCell>
                    <TableCell align="center">Eventos Registrados</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={id}>
                    <TableCell align="center" component="th" scope="row">
                      {type}
                    </TableCell>
                    <TableCell align="center">{price}</TableCell>
                    <TableCell align="center">{time}</TableCell>
                    <TableCell align="center">{place}</TableCell>
                    <TableCell align="center">{event_count}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Link to={`/proyectos/${slug}/${project_id}`}>
              <Button>Ver más detalle</Button>
            </Link>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

const Projects = ({ rows, resume }) => {
  function transformData(obj) {
    const result = {}
    obj.forEach((project) => {
      const { client_name, ...rest } = project
      if (!result[client_name]) {
        result[client_name] = { client_name, projects: [] }
      }
      result[client_name].projects.push(rest)
    })
    return Object.values(result)
  }

  const data = transformData(resume)

  return (
    <>
      {data.map((client, index) => (
        <Fragment key={client.client_name + index}>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            fontWeight="bold"
          >
            {client.client_name}
          </Typography>
          <Table style={{ marginBottom: 50 }} aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="center">Nombre del Proyecto</TableCell>
                <TableCell align="center">Último Cambio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* TODO: cambiar index por valor id unico */}
              {client.projects.map((project, index) => (
                <Row key={index} project={project} />
              ))}
            </TableBody>
          </Table>
        </Fragment>
      ))}
    </>
  )
}

export default Projects
