import {
  Box,
  Button,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { HomeContext } from '../../../context/HomeContext'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { queryLogin } from '../../../config/axios'
import Logo from '../../../assets/images/logo-principal-sf.png'

const Login = () => {
  const [, setAuth] = useContext(HomeContext)
  const navigate = useNavigate()
  const [errors, setErrors] = useState(false)
  const [user, setUser] = useState({ email: '', password: '' })

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/proyectos')
    }
  }, [navigate])

  const handleInputChange = (event) => {
    setUser((prevUser) => ({
      ...prevUser,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const response = await queryLogin('/auth-api/iniciar-sesion', user)
      const {
        token,
        accessTokenQuery,
        user: { id, name, email },
      } = response.data

      localStorage.setItem('id', id)
      localStorage.setItem('token', token)
      localStorage.setItem('name', name)
      localStorage.setItem('email', email)
      localStorage.setItem('accessTokenQuery', accessTokenQuery)

      setAuth((prevAuth) => ({
        ...prevAuth,
        id,
        isAuth: true,
        token,
        name,
        email,
        accessTokenQuery,
      }))

      Swal.fire({
        icon: 'success',
        title: `¡Bienvenid@, ${name.split(' ')[0]}!`,
        text: 'Has iniciado sesión correctamente',
        showConfirmButton: false,
        timer: 1500,
      })

      navigate('/proyectos')
    } catch (error) {
      const { msg } = error.response.data
      console.log(error)
      setErrors(true)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: msg,
      })
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        flex: '1 1 auto',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: 550,
          px: 3,
          py: '100px',
          width: '100%',
        }}
      >
        <Stack spacing={3}>
          <Typography variant="h4">Iniciar sesión</Typography>
          <Typography color="text.secondary" variant="body2">
            Debes solicitar una cuenta en caso de no tenerla
          </Typography>
        </Stack>

        <form onSubmit={handleSubmit}>
          <Stack spacing={3} sx={{ mt: 3 }}>
            <TextField
              fullWidth
              label="Correo electrónico"
              name="email"
              type="email"
              value={user.email}
              onChange={handleInputChange}
              error={errors}
            />
            <TextField
              fullWidth
              label="Contraseña"
              name="password"
              type="password"
              value={user.password}
              onChange={handleInputChange}
              error={errors}
            />
          </Stack>
          {errors && (
            <FormHelperText sx={{ mt: 1 }} error>
              Vuelve a revisar el correo electrónico y la contraseña
            </FormHelperText>
          )}
          <Button
            fullWidth
            size="large"
            sx={{ mt: 3 }}
            type="submit"
            variant="contained"
            disabled={!user.email || !user.password}
          >
            Iniciar sesión
          </Button>
        </form>
        <Stack spacing={1} sx={{ mt: 3 }}>
          <img src={Logo} alt="Megaphone" className="img-login" />
        </Stack>
      </Box>
    </Box>
  )
}

export default Login
