
import { Calendar } from 'primereact/calendar'
import { addLocale } from 'primereact/api'

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css"

//core
import "primereact/resources/primereact.min.css"

export const CalendarDate = ({ value, handleChange }) => {

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar'
  })
  return (
    <div style={{
      marginTop: 20
    }} className="card flex justify-content-center">
      <span className="p-float-label">
        <Calendar style={{
          width: '100%',
        }} name="event_date" value={value} showIcon onChange={handleChange} locale="es" dateFormat="dd/mm/yy" />
        <label htmlFor="birth_date">Fecha del evento</label>
      </span>
    </div>
  )
}