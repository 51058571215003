import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { useState } from 'react'

const SelectedProject = ({
  data,
  onChange,
  label,
  setProjectSelected,
  clientId,
}) => {
  const [selected, setSelected] = useState('')
  const handleChange = (event) => {
    setSelected(event.target.value)
    onChange(event.target.value)
    setProjectSelected((current) => ({ ...current, client_id: clientId })) // Actualizar el client_id en projectSelected.
  }

  // Verificar si data es un array antes de usar map
  if (!Array.isArray(data)) {
    return (
      <Typography>Error: Los datos proporcionados no son válidos</Typography>
    )
  }

  return (
    <Box>
      <h2> </h2>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          name="selected" // Agrega la propiedad name al elemento Select
          value={selected}
          onChange={handleChange}
        >
          {data.map((project) => (
            <MenuItem key={project.id} value={project.id}>
              {project.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default SelectedProject
