import { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { getProjectsByIdQuery } from '../../../config/axios'
import { useParams } from 'react-router-dom'
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Loading from '../../../sections/loading/Loading'
import { columns } from '../../../utils/columns'
import NotHaveEvents from './NotHaveEvents'
import { useTheme } from '@mui/material/styles'
import { CalendarDate } from '../../Common/CalendarDate'
import EventModalConfirm from '../../Common/EventModalConfirm'

export default function DataTable() {
  const { slug, id } = useParams()
  const [rows, setRows] = useState([])
  const [flag, setFlag] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [open, setOpen] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    const getClient = async (slug, id) => {
      try {
        const { data } = await getProjectsByIdQuery(
          '/events-api/proyectos-historicos',
          id
        )
        setRows(data.results)
        setFlag(true)
      } catch (error) {
        console.log(error)
      }
    }

    getClient(slug, id)
  }, [slug, id])

  const handleStartDateChange = (e) => {
    setStartDate(e.value)
  }

  const handleEndDateChange = (e) => {
    setEndDate(e.value)
  }

  const filteredEvents = rows.filter((event) => {
    const eventDate = new Date(event.event_date)
    return (
      event.dimension.toLowerCase().includes(searchText.toLowerCase()) &&
      (!startDate || eventDate >= startDate) &&
      (!endDate || eventDate <= endDate)
    )
  })

  const val = 0

  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }}>
      {flag ? (
        <Container>
          <Stack spacing={3}>
            {rows.length === 0 ? (
              <NotHaveEvents
                buttonText="Agregar evento"
                redirect="/ingresar-evento"
                text="Aún no tienes eventos asociados a este proyecto"
              />
            ) : (
              <>
                <div>
                  <Typography variant="h4" textAlign="center">
                    {rows[0].project_name}
                  </Typography>
                </div>
                <Stack spacing={1}>
                  <Typography variant="h4">Eventos</Typography>
                  <Stack>
                    <Typography>
                      En esta tabla puedes ver todos los eventos asociado a cada
                      proyecto
                    </Typography>
                    <Grid display="flex" justifyContent="end">
                      <Grid
                        marginRight={2}
                        sx={{ width: isSmallScreen ? '100%' : '30%' }}
                      >
                        <CalendarDate
                          value={startDate}
                          handleChange={handleStartDateChange}
                        />
                      </Grid>
                      <Grid sx={{ width: isSmallScreen ? '100%' : '30%' }}>
                        <CalendarDate
                          value={endDate}
                          handleChange={handleEndDateChange}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
                <div>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <DataGrid
                        rows={filteredEvents}
                        columns={columns}
                        pageSize={1}
                        onRowDoubleClick={(params) => {
                          setSelectedEvent(params.row)
                          setOpen(true)
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
                <Grid style={{ display: 'none' }} d>
                  <EventModalConfirm
                    button={'yes'}
                    open={open}
                    handleClose={() => setOpen(false)}
                    event={selectedEvent}
                    title="Detalles del evento"
                    type="event"
                  />
                </Grid>
              </>
            )}
          </Stack>
        </Container>
      ) : (
        <Loading />
      )}
    </Box>
  )
}

{
  /* <TextField
                      label="Buscar"
                      variant="outlined"
                      size="medium"
                      value={searchText}
                      onChange={handleSearch}
                      sx={{ width: isSmallScreen ? '100%' : '30%' }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    /> */
}
