import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import CreateNewClientDetails from './CreateNewClientDetails'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

/**
 * Componente para crear un nuevo cliente.
 *
 * Este componente muestra un formulario para crear un nuevo cliente.
 * Utiliza el componente CreateNewClientDetails para mostrar los detalles
 * específicos de la creación del cliente.
 */
const CreateClient = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      localStorage.clear()
      navigate('/proyectos')
    }
  }, [navigate])

  return (
    // Box sirve como contenedor principal
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <Typography variant="h4">Crear un nuevo cliente</Typography>
          <Grid container>
            <Grid xs={12} item>
              <CreateNewClientDetails />
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  )
}

export default CreateClient
