import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import PostAddIcon from '@mui/icons-material/PostAdd'
import BackupTableIcon from '@mui/icons-material/BackupTable'

export const adminPaths = [
  // {
  //   index: 1,
  //   title: 'Inicio',
  //   path: '/',
  //   icon: <HomeIcon sx={{ color: '#fafafa' }} />,
  // },
  {
    index: 4,
    title: 'Ingresar cliente',
    path: '/crear-cliente',
    icon: <PersonAddAlt1Icon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 3,
    title: 'Proyectos',
    path: '/proyectos',
    icon: <BackupTableIcon sx={{ color: '#fafafa' }} />,
  },
  // {
  //   index: 2,
  //   title: 'Reporte',
  //   path: '/reporte',
  //   icon: <DashboardIcon sx={{ color: '#fafafa' }} />,
  // },

  {
    index: 5,
    title: 'Registrar evento',
    path: '/ingresar-evento',
    icon: <PostAddIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 6,
    title: 'Dar de baja',
    path: '/dar-de-baja',
    icon: <PersonRemoveAlt1Icon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 7,
    title: 'Proyectos históricos',
    path: '/clientes-historicos',
    icon: <PersonRemoveAlt1Icon sx={{ color: '#fafafa' }} />,
  },

  {
    index: 8,
    title: 'Mi perfil',
    path: '/editar-perfil',
    icon: <ManageAccountsIcon sx={{ color: '#fafafa' }} />,
  },
  {
    index: 9,
    title: 'Cerrar sesión',
    path: '/iniciar-sesion',
    icon: <ExitToAppIcon sx={{ color: '#fafafa' }} />,
  },
]
